<template>
  <div class="project top-fix">
    <van-nav-bar title="项目汇总" fixed left-arrow @click-left="$router.back(-1)" />
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList(page)">
      <div v-for="(item, index) in list" :key="index">
        <ListItem :item="item" :navTo="navTo" />
      </div>
    </van-list>
  </div>
</template>
<script>
import Projects from "@/api/project.js";
import ListItem from "@/components/project/ListItem.vue";
export default {
  components: {
    ListItem
  },
  data() {
    return {
      list: [],
      lastPage: 10,
      page: 0,
      loading: false,
      navTo: "Summarize"
    };
  },
  computed: {
    finished() {
      return this.lastPage <= this.page;
    }
  },
  methods: {
    getList(page) {
      Projects.projectlist(page + 1)
        .then(res => {
          this.lastPage = res.data.last_page;
          this.page = res.data.page;
          if (page == 0) {
            this.list = res.data.list;
          } else {
            this.list = this.list.concat(res.data.list);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    }
  },
  created: function() {}
};
</script>

<style lang="scss">
.project {
  padding: 50px 0 70px;
}
</style>
